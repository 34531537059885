const awsExports = {
    "Auth": {
        "Cognito": {
            "userPoolClientId": window.userPoolClientId,
            "userPoolId": window.userPoolId,
            "loginWith": { // Optional
              "oauth": {
                "domain": window.domain,
                "scopes": ['openid email phone profile aws.cognito.signin.user.admin '],
                "redirectSignIn": window.redirectSignIn,
                "redirectSignOut": window.redirectSignOut,
                "responseType": 'code',
              },
              "username": 'false',
              "email": 'true', // Optional
              "phone": 'false', // Optional
            }
          }
    },
    "API":{
      "REST":{
        "api":{
          "endpoint":"https://api.aeromancy.mobi",
          "region":"us-east-1"
        }
      }
    }
};


export default awsExports;