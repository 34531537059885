import React, { Component, useEffect, useRef, useState } from 'react';
function Question(props) {
    const [question, setQ] = useState("What do you see in my fortune?");
    function askQuestion(){
        console.log("Asking Question")
        console.log(question)
        props.questionCallback(question)
    }
    return(
        <div className='QuestionForm'>
            <label htmlFor="question">Ask me anything</label><br/>
            <textarea type="text" id="question" name="question" onChange={e => setQ(e.target.value)} value={question}></textarea>
            <br/>
            <button onClick={askQuestion} >Ask</button>
        </div>
    )
}
export default Question;