import React, { Component, useEffect, useRef, useState } from 'react';



function ResultScreen(props) {
    console.log('props', props)
    const picture = props.picture
    const reading = " Ah, the clouds speak to me of great change and transformation! *adjusting monocle* The shape of a dog indicates a loyal companionship entering your life, one that will bring joy and comfort. A cat suggests a mysterious and elusive figure will cross your path, bringing both challenge and opportunity. A bird heralds the arrival of a new creative pursuit, which will bring success and fulfillment. And a fish swims in the waters of my mind, indicating a journey across the seas, perhaps a move or long-distance travel. But beware, my dear, for a car speeding towards you warns of danger and haste, urging caution in all your endeavors. Heed these signs well, and remember: the winds of fate blow ever changing, yet within each gust lies the power to soar or stumble."
    const signs = {
        "A dog": [
            10,
            10,
            20,
            20
        ],
        "A cat": [
            20,
            20,
            30,
            30
        ],
        "A bird": [
            30,
            30,
            40,
            40
        ],
        "A fish": [
            40,
            40,
            50,
            50
        ],
        "A car": [
            50,
            50,
            60,
            60
        ]
    }
    var signDisplays = [];
    if (props.reading.signs !== undefined) {
        for (var sign in props.reading.signs) {
            signDisplays.push(<div key={sign} className="ResultSign" style={{left:props.reading.signs[sign][0]+'%', top: props.reading.signs[sign][1]+'%',right:(100-props.reading.signs[sign][2])+'%',bottom:(100-props.reading.signs[sign][3])+'%'}}><span>{sign}</span></div>);
        }
    }
    return(
        <div className='ResultScreen'>
            <div className='ResultImage'>
                <img src={props.picture} alt='Reading'/>
                {signDisplays}
                {(props.reading.status !== 'done') && <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
            </div>
            <div className='ResultText'><span>{props.reading.reading}</span></div>
            {props.reading.status !== 'done' &&<div className="lds-ripple"><div></div><div></div></div>}
            {props.reading.status === 'done' && <button onClick={props.newReading}>New Reading</button>}
        </div>
    )
}
export default ResultScreen;