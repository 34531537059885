import React, { Component, useEffect, useRef, useState } from 'react';
import './App.css';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { withAuthenticator, Button, ThemeProvider, components } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/ui-react/styles/reset.layer.css' // global CSS reset
import '@aws-amplify/ui-react/styles/base.layer.css' // base styling needed for Amplify UI
import '@aws-amplify/ui-react/styles/button.layer.css' // component specific styles
import Screen from './components/SimpleClouds';
import Result from './components/ResultScreen'
import Question from './components/Question';
import { post,get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth'
Amplify.configure(awsExports);

const authComponents ={
  Header(){
    return(
      <div>
        <h1 className='LoginHeader'>Aeromancy</h1>
      </div>
    )
  },
  Footer(){
    return(
    <div>
      <span className='LoginFooter'>Copyright 2024 Aeromancy</span>
    </div>
    )
  }
}
const authFormFields = {

};

async function CreateFortune(picture,question,updateReading) {
  const sleep =(ms) =>{
    return new Promise(resolve => setTimeout(resolve, ms));
  }
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      console.log('accessToken', authToken);
      const todo = {question:question};
      const restOperation = post({
        apiName: 'api',
        path: '/readings',
        options: {
          headers: {
            Authorization: authToken
          },
          body: todo
        }
      });
      const response = await restOperation.response;
      const result = await response.body.json();
      console.log('POST call succeeded: ', response);
      console.log(result)
      const s3uploadResult = await fetch(result.upload_url, {
        method: 'PUT',
        body: picture
      })
      const done = false;
      while(!done){
        await sleep(3000);
        const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        const statusUpdate = await get({
          apiName: 'api',
          path: '/readings/'+result.reading_id,
          options: {
            headers: {
              Authorization: authToken
            }
          }
        });
        const statusResponse = await statusUpdate.response;
        const reading = await statusResponse.body.json();
        updateReading(reading);
        if(reading.status === 'done'){
          done = true;
        }
        console.log(reading)
      }
    } catch (e) {
      console.log('POST call failed: ', e);
    }
  }
function App({ signOut, user }) {
  const [showing, setShowing] = useState(0);
  const [picture, setPicture] = useState(null);
  const [reading, setReading] = useState({});
  const [question, setQuestion] = useState("");
  const updateReading = (reading) => {
    setReading(prev => prev = reading);
  }
  const tookPicture = (picture,binary) =>{
    console.log('Got Picture')
    setShowing(prev => prev = 2);
    setPicture(prev => prev = picture);
    setReading(prev => prev = {});
    CreateFortune(binary, question,updateReading);
  }
  const questionCallback = (question) =>{
    console.log('Question Answered')
    setShowing(prev => prev = 1);
    setQuestion(prev => prev = question);
  }
  const newReading = () => {
    setShowing(prev => prev = 0);
    setPicture(prev => prev = null);
    setQuestion(prev => prev = "");
    setReading(prev => prev = {});

  }
  console.log(user);

  return (
      <div className='main'>
        <div className='Header'>
          <h1>Aeromancy</h1>
          <button  onClick={signOut}>Sign out</button>
        </div>
        {(() => {
                switch(showing) {
                  case 0:
                    return <Question questionCallback={questionCallback}></Question>
                  case 1:
                    return <Screen pictureCallback={tookPicture}/>
                  case 2:
                    return <Result picture={picture} newReading={newReading} reading={reading} />
                }
          })()}
            
        <div className='footer'></div>
      </div>
  );
}

export default withAuthenticator(App,{components:authComponents,loginMechanisms:['email'],formFields:authFormFields});