import React, { Component, useEffect, useRef, useState } from 'react';
import * as THREE from "three"
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Clouds, CameraControls,  Cloud, Sky as SkyImpl } from '@react-three/drei'
let gl = null
function Sky() {
   
   const ref = useRef()
   const cloud0 = useRef()
   const cloud1 = useRef()
   const cloud2 = useRef()
   gl = useThree(state => state.gl)
   const { color, x, y, z, range, ...config } = {
    segments: 50,
    volume: 40,
    opacity: 1.0,
    fade: 100,
    growth: 15,
    speed: 0.1,
    concentrate:"outside",
    color: "white",
    bounds: 150
   }
   useFrame((state, delta) => {
    ref.current.rotation.y = Math.cos(state.clock.elapsedTime / 2) / 5
    ref.current.rotation.x = Math.sin(state.clock.elapsedTime / 2) / 5
    cloud0.current.rotation.y -= delta/2
    //cloud0.current.rotation.z -= delta/3
    //cloud0.current.rotation.x -= delta/3
    cloud1.current.rotation.y -= delta/3
    cloud1.current.rotation.x = Math.sin(state.clock.elapsedTime / 2) / 2
    //cloud1.current.rotation.x -= delta/4
    cloud2.current.rotation.y -= delta/5
    cloud2.current.rotation.x = Math.sin(state.clock.elapsedTime / 2) / 4
    //cloud2.current.rotation.z -= delta/2
    //cloud2.current.rotation.x -= delta/2
  })
    return (
      <>
      <SkyImpl />
      <group ref={ref}>
        <Clouds material={THREE.MeshLambertMaterial} limit={500} range={range}>
          <Cloud ref={cloud0} {...config} color={color} />
          <Cloud ref={cloud1} {...config} color={color} />
          <Cloud ref={cloud2} {...config} color={color} />
          <Cloud {...config} volume={40} segments={150}  color={'#ffaaaa'} />
        </Clouds>
      </group>
      </>
  
    )
  }
    
  function ReadingScreen(props) {  
    console.log('pros', props)
    function handleButtonClick() {
      console.log('The link was clicked.');
      gl.domElement.toBlob((blob) => {
        props.pictureCallback(gl.domElement.toDataURL('image/png').replace("image/png", "image/octet-stream"),blob);
      },"image/png",1.0);
      
    }
    return (
      <div className='SimpleReader'>
        <div className='ReaderViewer'>
        <Canvas className='CloudsCapture' gl={{ preserveDrawingBuffer: true }} camera={{ position: [0, -10, 10], fov: 75 }}>
          <Sky />
          <ambientLight intensity={Math.PI / 1.5} />
          <spotLight position={[0, 40, 0]} decay={0} distance={45} penumbra={1} intensity={100} />
          <spotLight position={[-20, 0, 10]} color="#8888FF" angle={0.15} decay={0} penumbra={-1} intensity={50} />
          <spotLight position={[20, -10, 10]} color="#FF8888" angle={0.2} decay={0} penumbra={-1} intensity={50} />
          <CameraControls />
        </Canvas>
        </div>
        <button className='PictureButton' onClick={handleButtonClick} ></button>
      </div>
    )
  
  }
export default ReadingScreen;